import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const BlueBubble = styled(Box)`
  background-color: ${({ theme }) => theme.colors.nextonBlue};
  position: absolute;
  top: 40px;
  left: 54%;
  width: 71px;
  height: 71px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    // top: 31%;
    // display: none;
  }
`;

const YellowBubble = styled(Box)`
  background-color: ${({ theme }) => theme.colors.nextonYellow};
  position: absolute;
  top: 300px;
  right: 12%;
  width: 99px;
  height: 99px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    // top: 490px;
    right: -4%;
  }
`;

const LightOrangeBubble = styled(Box)`
  background-color: #fecaa4;
  position: absolute;
  top: 460px;
  left: 18%;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    // top: 650px;
    // right: 60%;
    left: -13%;
  }
`;

const ThankYouBubbles = () => (
  <Box>
    <BlueBubble />
    <YellowBubble />
    <LightOrangeBubble />
  </Box>
);

export default ThankYouBubbles;
