import React from 'react';
import { Box } from 'rebass';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import AcademyContactThankYouHero from '../sections/academy/AcademyContactThankYouHero';

const AcademyContactThankYouPage = () => (
  <>
    <MetaData
      title='Nexton Academy Thank you for contacting'
      description='Thank you for connecting with Nexton Academy. Your journey begins here!'
      image='https://www.nextonlabs.com/academy-banner.png'
    />
    <Layout selected='academy-contact-thank-you'>
      <Box className='full-width' bg='nextonLightGrey' minHeight='calc(100vh - 100px)'>
        <AcademyContactThankYouHero />
      </Box>
    </Layout>
  </>
);

export default AcademyContactThankYouPage;
